import React from "react"
import { Link } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import { PrismicArticle } from "../../graphql-types"
import { getI18n } from "react-i18next"

const Excerpt = ({ prismicArticle }: { prismicArticle: PrismicArticle }) => {
  const { language } = getI18n()

  if (!prismicArticle?.url) return null

  const currentLanguageUrl = prismicArticle.url.replace(
    /[a-z]{2}-[a-z]{2}/,
    language
  )

  let excerptImageFluid = prismicArticle.data?.image?.fluid
  if (excerptImageFluid)
    excerptImageFluid.sizes = `(min-width: 33rem) 31rem, calc(100vw - 2rem)`

  return (
    <article className="excerpt xl:w-col">
      <Link to={currentLanguageUrl}>
        {excerptImageFluid && (
          <Img
            alt={prismicArticle.data?.image?.alt ?? ``}
            className="w-full mb-1"
            fluid={excerptImageFluid as FluidObject}
          />
        )}
        <h4 className="my-2 uppercase">{prismicArticle.data?.title}</h4>
        <p className="text-md text-gray-900">
          {prismicArticle.data?.excerpt?.text}
        </p>
      </Link>
    </article>
  )
}

export default Excerpt
export { Excerpt }
