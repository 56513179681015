import React, { useEffect, useRef, useState } from "react"
import { graphql, PageProps } from "gatsby"
import { usePreviewData } from "../hooks/usePreview"
import { useMedia } from "../hooks/useMedia"
import { PrismicArticleConnection } from "../../graphql-types"
import Excerpt from "../components/excerpt"
import Bricks from "bricks.js"

const IndexTemplate = ({
  data: staticData,
}: PageProps<{ allPrismicArticle: PrismicArticleConnection }>) => {
  const data = usePreviewData(staticData),
    gridRef = useRef<HTMLDivElement>(null),
    [bricks, setBricks] = useState<Bricks.Instance>(),
    viewport = useMedia([`(min-width: 1184px)`], /* 74rem */ [4], 0)

  useEffect(() => {
    // If no instance is stored and we have a ref
    if (bricks) return
    if (!gridRef.current) return

    // Create instance
    const instance = Bricks({
      container: gridRef.current,
      packed: `packed`,
      sizes: [
        { columns: 1, gutter: 64 },
        { mq: `74rem`, columns: 2, gutter: 64 },
      ],
    })
      .resize(true)
      // Observe resize for teardown on small screens
      .on(`resize`, (size) => {
        if (size.columns === 1) {
          gridRef.current?.removeAttribute(`style`)
          gridRef.current
            ?.querySelectorAll(`[data-packed]`)
            .forEach((element) => {
              element.removeAttribute(`style`)
              element.removeAttribute(`data-packed`)
            })
        }
      })

    setBricks(instance)
  }, [bricks, gridRef, viewport])

  useEffect(() => {
    if (bricks && viewport > 0) bricks.pack().pack()
  })

  return (
    <div
      ref={gridRef}
      className="index w-col xl:w-2col -max-w-4 sm:max-w-full mx-auto my-16 grid gap-10 xl:gap-16 grid-flow-row xl:grid-cols-2"
    >
      {data.allPrismicArticle.edges.map((edge) => (
        <Excerpt prismicArticle={edge.node} />
      ))}
    </div>
  )
}

export default IndexTemplate
export { IndexTemplate }

export const IndexQuery = graphql`
  query ArticlesByLang($lang: String!) {
    allPrismicArticle(
      filter: { lang: { eq: $lang } }
      sort: { order: DESC, fields: first_publication_date }
    ) {
      edges {
        node {
          alternate_languages {
            id
            lang
            type
            uid
          }
          data {
            excerpt {
              text
            }
            image {
              ... on PrismicImageType {
                ...PrismicImageType
              }
            }
            title
          }
          id
          lang
          prismicId
          type
          uid
          url
        }
      }
      totalCount
    }
  }
`
